@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
// TODO: figure out how to automate font installation
.glyphicon-spin {
  -webkit-animation: spin 1000ms infinite linear;
  animation: spin 1000ms infinite linear;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.form-group.required > label:after {
  color: #d00;
  content: "*";
}
body.waiting * {
  cursor: progress;
}
a.list-group-item {
  margin-bottom: 1px;
}
.point-cursor {
  cursor: pointer;
}

.field-description {
  color: #737373;
}
.field-description::before {
  content: "(";
}
.field-description::after {
  content: ")";
}

a.navbar-brand {
  padding: 0;
}

.dz-error-message {
  top: 150px !important;
  opacity: 1 !important;
}

.has-error .form-control {
  border-width: medium;
}

// use colors from list group items for glyphicons in panel headers
.panel-glyph {
  background-color: white;
  padding-top: 3px;
  padding-left: 4px;
  border-radius: 3px;
  margin-right: 6px;
}
.panel-glyph > .glyphicon-exclamation-sign {
  color: $state-danger-text;
}
.panel-glyph > .glyphicon-ok {
  color: $state-success-text;
}
.booking-title {
  vertical-align: text-top;
}